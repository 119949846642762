import { SCRIPT_LOADING_TIME_WAIT } from "../constants/consts";

export const titleCase = str => {
  const splitStr = str.toLowerCase().split(" ")
  for (let i = 0; i < splitStr.length; i++) {
    // You do not need to check if i is larger than splitStr length, as your for does that for you
    // Assign it back to the array
    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1)
  }
  // Directly return the joined string
  return splitStr.join(" ")
}

export const isMobileScreen = () => {
  return window?.screen.width <= 768
}

export const googleScriptLoadingDelay = () => {
  return !document.querySelector('script[src*="gtm"]') ? SCRIPT_LOADING_TIME_WAIT : 1
}

export const delayMultiplicationFactor = (isMobile = false) => {
  return isMobile ? 2.5 : 1
}

export const isBrowser = () => typeof window !== "undefined";
